<template>
  <b-form-group
    class="mb-0 p-0"
    :class="`
    ${contextErrors ? 'is-invalid' : ''}
    ${size ? `form-control-${size}` : ''}
    `"
  >
    <v-select
      :id="id"
      v-model="selectedData"
      :options="nationalOptions"
      label="longCode"
      :class="`
      v-select-wrap
      ${['sm'].includes(size) ? 'select-size-sm' : ''}
      `"
      :loading="loading"
      :placeholder="placeholder ?? $t('flight.placeholderSelect')"
      :filter="filterCountries"
      @open="openCountrySelect"
      @input="handleUpdateSelect"
    >
      <template #selected-option="item">
        <div class="text-nowrap text-truncate">
          <b>{{ item.countryName }}</b>
          <span class="text-warning fw-700">
            {{ item.longCode }} - {{ item.shortCode }}
          </span>
        </div>
      </template>
      <template #option="item">
        <div class="d-flex-center justify-content-between">
          <b>{{ item.countryName }}</b>
          <span class="text-warning fw-700">
            {{ item.longCode }} - {{ item.shortCode }}
          </span>
        </div>
      </template>
      <template #spinner="data">
        <div
          v-if="data.loading"
          style="border-left-color: rgba(88, 151, 251, 0.71)"
          class="vs__spinner"
        />
      </template>
      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>

    <b-tooltip
      v-if="contextErrors"
      :target="id"
      triggers="hover focus"
      placement="top"
      boundary="window"
      variant="danger"
      :title="contextErrors"
    >
      <span class="text-white py-25 mb-0">
        {{ contextErrors }}
      </span>
    </b-tooltip>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BTooltip,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BFormGroup,
    BTooltip,

    vSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Object, null],
      default: null,
    },
    contextErrors: {
      type: [String, undefined],
      default: undefined,
    },
    placeholder: {
      type: [String, null],
      default: null,
    },
    size: {
      type: String,
      default: 'md',
    },
    isReturnShortCode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const selectedData = ref(props.value)
    const nationalOptions = ref([])
    const { value } = toRefs(props)

    watch(value, newVal => {
      if (newVal && (newVal?.id !== selectedData.value?.id)) {
        selectedData.value = newVal
      }
    })

    async function openCountrySelect() {
      if (isEmpty(nationalOptions.value)) {
        const allCountries = store.getters['globalConfig/getAllCountries']
        if (isEmpty(allCountries)) {
          try {
            loading.value = true
            await store.dispatch('globalConfig/fetchAllCountries')
            nationalOptions.value = store.getters['globalConfig/getAllCountries']
          } catch (error) {
            console.error(error)
          } finally {
            loading.value = false
          }
        } else {
          nationalOptions.value = allCountries
        }
      }
    }

    function filterCountries(options, search) {
      return options.filter(option => {
        const label = `${option?.countryName} ${option?.longCode} ${option?.shortCode}`
        const formatSearchText = removeAccents(search).replace(/\s+/g, '').toLocaleLowerCase()
        const formatLabel = removeAccents(label).replace(/\s+/g, '').toLocaleLowerCase()
        return formatLabel.includes(formatSearchText)
      })
    }

    function handleUpdateSelect() {
      emit('input', selectedData.value)
    }

    return {
      selectedData,
      nationalOptions,
      loading,
      handleUpdateSelect,
      openCountrySelect,
      filterCountries,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.v-select-wrap {
  .vs__selected-options {
    flex-wrap: nowrap;
    .vs__selected {
        padding: 0;
        margin: 0;
    }
  }
  .vs__dropdown-menu {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  }
}
</style>
