var render = function () {
  var _vm$placeholder;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    staticClass: "mb-0 p-0",
    class: "\n  ".concat(_vm.contextErrors ? 'is-invalid' : '', "\n  ").concat(_vm.size ? "form-control-".concat(_vm.size) : '', "\n  ")
  }, [_c('v-select', {
    class: "\n    v-select-wrap\n    ".concat(['sm'].includes(_vm.size) ? 'select-size-sm' : '', "\n    "),
    attrs: {
      "id": _vm.id,
      "options": _vm.nationalOptions,
      "label": "longCode",
      "loading": _vm.loading,
      "placeholder": (_vm$placeholder = _vm.placeholder) !== null && _vm$placeholder !== void 0 ? _vm$placeholder : _vm.$t('flight.placeholderSelect'),
      "filter": _vm.filterCountries
    },
    on: {
      "open": _vm.openCountrySelect,
      "input": _vm.handleUpdateSelect
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(item) {
        return [_c('div', {
          staticClass: "text-nowrap text-truncate"
        }, [_c('b', [_vm._v(_vm._s(item.countryName))]), _c('span', {
          staticClass: "text-warning fw-700"
        }, [_vm._v(" " + _vm._s(item.longCode) + " - " + _vm._s(item.shortCode) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(item) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_c('b', [_vm._v(_vm._s(item.countryName))]), _c('span', {
          staticClass: "text-warning fw-700"
        }, [_vm._v(" " + _vm._s(item.longCode) + " - " + _vm._s(item.shortCode) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(data) {
        return [data.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedData,
      callback: function callback($$v) {
        _vm.selectedData = $$v;
      },
      expression: "selectedData"
    }
  }), _vm.contextErrors ? _c('b-tooltip', {
    attrs: {
      "target": _vm.id,
      "triggers": "hover focus",
      "placement": "top",
      "boundary": "window",
      "variant": "danger",
      "title": _vm.contextErrors
    }
  }, [_c('span', {
    staticClass: "text-white py-25 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.contextErrors) + " ")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }